<template>
  <div>
    <b-row class="justify-content-md-center">
      <b-col cols="8">
        <b-card
          :title="$t('Documents.RUT Processing.title')"
        >
          <b-form @submit.prevent="submitRut">
            <b-row>
              <!-- first name -->
              <b-col cols="12">
                <b-form-group
                  :label="$t('Documents.RUT Processing.rut.label')"
                  :state="validityRut"
                  :invalid-feedback="$t('Documents.RUT Processing.rut.feedback')"
                >
                  <b-input-group
                    class="input-group-merge"
                  >
                    <b-input-group-prepend is-text>
                      <b-icon-building />/<b-icon-person />
                    </b-input-group-prepend>
                    <b-form-input
                      id="vi-rut"
                      v-model="rut"
                      placeholder="11.111.111-1"
                      :disabled="!inputEnabled"
                      @keyup="onKeyUpRut"
                      @focusout="onLoseFocus"
                    />
                    <b-button
                      type="submit"
                      variant="primary"
                      class="mr-1"
                    >
                      {{ $t('Documents.RUT Processing.rut.buttonText') }}
                    </b-button>
                  </b-input-group>

                </b-form-group>
              </b-col>

              <b-col>
                <b-form-group :label="$t('Documents.RUT Processing.type.label')">
                  <b-form-radio-group
                    v-model="selected"
                    :disabled="isRutPersonaNatural"
                    :options="options"
                    name="radios-stacked"
                    stacked
                  />
                </b-form-group>
              </b-col>

              <!-- reset and submit -->
              <b-col cols="12">

                <!--b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    type="reset"
                    variant="outline-secondary"
                >
                  Reset
                </b-button-->
              </b-col>
            </b-row>
          </b-form>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>

import { validateRut } from '@fdograph/rut-utilities'
import {
  BRow, BCol, BFormGroup, BFormInput, BForm, BButton, BInputGroup, BInputGroupPrepend, BCard, BFormRadioGroup, BIconBuilding, BIconPerson,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BForm,
    BButton,
    BCard,
    BFormRadioGroup,
    BIconBuilding,
    BIconPerson,
  },
  Components: {
    ToastificationContent,
  },
  data() {
    return {
      data: {},
      rut: '',
      validityRut: undefined,
      isRutPersonaNatural: false,
      inputEnabled: true,
      selected: 3,
      options: [
        // Estas definiciones deben coincidir con las definidas por el gRPC en bridge
        // For 'es' locale: Bancarias Normales
        { text: this.$i18n.t('Documents.RUT Processing.type.options.3'), value: 3 },
        // For 'es' locale: Bancarias Especiales
        { text: this.$i18n.t('Documents.RUT Processing.type.options.2'), value: 2 },
        // For 'es' locale: Operaciones de Crédito
        { text: this.$i18n.t('Documents.RUT Processing.type.options.4'), value: 4 },
      ],
    }
  },
  sockets: {
    onJobStatusUpdate(data) {
      console.log(data)
      if (data.error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$i18n.t('Toast.titles.withID', { id: data.job_id }),
            icon: 'BellIcon',
            text: this.$i18n.t('Toast.texts.couldntReport'),
            variant: 'danger',
          },
        })
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$i18n.t('Toast.titles.withID', { id: data.job_id }),
            icon: 'BellIcon',
            text: this.$i18n.t('Toast.texts.successReport'),
            variant: 'success',
          },
        },
        {
          // Check https://github.com/Maronato/vue-toastification#toast-options-object
          // for more options!
          onClick: closeToast => {
            // console.log(`Clicked toast for process: ${data.job_id}`)
            // Toast will close regardless of success/failure of router.push
            this.$router.push('/documents/list-jobs', closeToast, closeToast)
          },
        })
      }
    },
  },
  watch: {
    isRutPersonaNatural(isNatural) {
      if (isNatural) {
        this.options = [
          // For 'es' locale: Persona Natural
          { text: this.$i18n.t('Documents.RUT Processing.type.options.0'), value: 0 },
        ]
        this.selected = 0
      } else if (this.$ability.can('manage', 'all')) {
        this.options = [
          // For 'es' locale: Bancarias Normales
          { text: this.$i18n.t('Documents.RUT Processing.type.options.3'), value: 3 },
          // For 'es' locale: Bancarias Especiales
          { text: this.$i18n.t('Documents.RUT Processing.type.options.2'), value: 2 },
          // For 'es' locale: Operaciones de Crédito
          { text: this.$i18n.t('Documents.RUT Processing.type.options.4'), value: 4 },
          // For 'es' locale: MAF
          { text: this.$i18n.t('Documents.RUT Processing.type.options.5'), value: 5 },
          // For 'es' locale: Capitaria
          { text: this.$i18n.t('Documents.RUT Processing.type.options.7'), value: 7 },
        ]
        this.selected = 3
      } else {
        const userData = JSON.parse(localStorage.getItem('userData'))
        if (userData.group.String.toLowerCase() === 'maf') {
          this.options = [
            // Fox 'es' locale: MAF
            { text: this.$i18n.t('Documents.RUT Processing.type.options.5'), value: 5 },
          ]
          this.selected = 5
        } else if (userData.group.String.toLowerCase() === 'capitaria') {
          this.options = [
            { text: this.$i18n.t('Documents.RUT Processing.type.options.7'), value: 7 },
          ]
        } else {
          this.options = [
            // For 'es' locale: Bancarias Normales
            { text: this.$i18n.t('Documents.RUT Processing.type.options.3'), value: 3 },
            // For 'es' locale: Bancarias Especiales
            { text: this.$i18n.t('Documents.RUT Processing.type.options.2'), value: 2 },
            // For 'es' locale: Operaciones de Crédito
            { text: this.$i18n.t('Documents.RUT Processing.type.options.4'), value: 4 },
          ]
          this.selected = 3
        }
      }
    },
  },
  mounted() {
    const userData = JSON.parse(localStorage.getItem('userData'))
    if (this.$ability.can('manage', 'all')) {
      this.options = [
        // For 'es' locale: Bancarias Normales
        { text: this.$i18n.t('Documents.RUT Processing.type.options.3'), value: 3 },
        // For 'es' locale: Bancarias Especiales
        { text: this.$i18n.t('Documents.RUT Processing.type.options.2'), value: 2 },
        // For 'es' locale: Operaciones de Crédito
        { text: this.$i18n.t('Documents.RUT Processing.type.options.4'), value: 4 },
        // For 'es' locale: MAF
        { text: this.$i18n.t('Documents.RUT Processing.type.options.5'), value: 5 },
        // For 'es' locale: CAPITARIA
        { text: this.$i18n.t('Documents.RUT Processing.type.options.7'), value: 7 },
      ]
      this.selected = 3
    } else if (userData.group.String.toLowerCase() === 'maf') {
      this.options = [
        // For 'es' locale: MAF
        { text: this.$i18n.t('Documents.RUT Processing.type.options.5'), value: 5 },
      ]
      this.selected = 5
    } else if (userData.group.String.toLowerCase() === 'capitaria') {
      this.options = [
        { text: this.$i18n.t('Documents.RUT Processing.type.options.7'), value: 7 },
      ]
    } else {
      this.options = [
        // For 'es' locale: Bancarias Normales
        { text: this.$i18n.t('Documents.RUT Processing.type.options.3'), value: 3 },
        // For 'es' locale: Bancarias Especiales
        { text: this.$i18n.t('Documents.RUT Processing.type.options.2'), value: 2 },
        // For 'es' locale: Operaciones de Crédito
        { text: this.$i18n.t('Documents.RUT Processing.type.options.4'), value: 4 },
      ]
      this.selected = 3
    }
  },
  created() {
    this.$nextTick(() => {
      // TODO: Clean this mess
      if (process.env.VUE_APP_VERSION === 'MAF') {
        this.options = [
          { text: this.$i18n.t('Documents.RUT Processing.type.options.5'), value: 5 },
        ]
        this.selected = 5
      } else if (process.env.VUE_APP_VERSION === 'CAPITARIA') {
        this.options = [
          { text: this.$i18n.t('Documents.RUT Processing.type.options.7'), value: 7 },
        ]
        this.selected = 7
      }
      /* window.io.on('reply', reply => {
        console.log(reply)
      }) */
    })
    // console.log('Call backend if you want')
  },
  methods: {
    onLoseFocus() {
      this.validityRut = validateRut(this.rut.toLowerCase())
    },
    testButton() {
      console.log('Hello there')
      // window.io.emit('notice', 'Hello there!')
      // this.$socket.emit('addJobToWaitingList', jobId)
    },
    onKeyUpRut() {
      const temp = this.rut.replace(/[^\dkK]/g, '')
      if (temp.length > 1) {
        const dv = temp.slice(-1)
        const base = parseFloat(temp.slice(0, -1)).toLocaleString('de-DE')
        if (process.env.VUE_APP_VERSION !== 'MAF' && process.env.VUE_APP_VERSION !== 'CAPITARIA') {
          this.isRutPersonaNatural = temp.slice(0, -1) < 45000000
        }
        this.rut = `${base}-${dv}`
      } else {
        this.rut = temp
      }
      // console.log(this.inputID)
    },
    submitRut() {
      if (validateRut(this.rut.toLowerCase())) {
        this.inputEnabled = false
        this.$http.post('/v2/consulta-rut/any', {
          rutAdmin: '',
          rut: this.rut.toLowerCase(),
          analysisType: this.selected,
        })
          .then(response => {
            this.showSuccessToast(response.data.jobId, response.data.rut)
            this.clearInput()
            this.inputEnabled = true
          })
          .catch(error => {
            this.showFailedToast(error)
            this.inputEnabled = true
          })
      } else {
        this.$swal({
          title: this.$i18n.t('SwAl.titles.invalidRUT'),
          html: this.$i18n.t('SwAl.htmls.verifyRUT'),
          icon: 'error',
          showCancelButton: false,
          confirmButtonText: this.$i18n.t('SwAl.buttonTexts.ok'),
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      }
    },
    clearInput() {
      this.rut = ''
      this.selected = 3
    },
    showFailedToast(error) {
      console.log(error)
      this.$toast({
        component: ToastificationContent,
        props: {
          title: this.$i18n.t('Toast.titles.errNoID'),
          icon: 'BellIcon',
          text: this.$i18n.t('Toast.texts.couldntRequest', { rut: this.rut }),
          variant: 'danger',
        },
      })
    },
    showSuccessToast(jobId, rut) {
      // TODO: Subscribe to the job status update
      console.log('adding job id to watch', jobId)
      this.$socket.emit('addJobToWaitingList', jobId)
      this.$toast({
        component: ToastificationContent,
        props: {
          title: this.$i18n.t('Toast.titles.withID', { id: jobId }),
          icon: 'BellIcon',
          text: this.$i18n.t('Toast.texts.successRequest', { rut }),
          variant: 'success',
        },
      },
      {
        // Check https://github.com/Maronato/vue-toastification#toast-options-object
        // for more options!
        onClick: closeToast => {
          // console.log(`Clicked toast for process: ${jobId}`)
          // Toast will close regardless of success/failure of router.push
          this.$router.push('/documents/list-jobs', closeToast, closeToast)
        },
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
.swal2-shown { display:inline; height:100vh; }
</style>
